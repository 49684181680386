import React from 'react';

import history from '@guestyci/history';

import createStyles from '@guestyci/foundation/createStyles';
import Col from '@guestyci/foundation/Layout/Col';
import RaisedButton from '@guestyci/foundation/RaisedButton';
import Icon from '@guestyci/foundation/Icon';
import useTranslations from '@guestyci/localize/useTranslations';

import { utils } from 'utils';

import Title from '../components/Title';
import Subtitle from '../components/Subtitle';

import { ReactComponent as SentIcon } from '../assets/mailSentIcon.svg';

const useStyles = createStyles(theme => ({
  icon: {
    width: 120,
    height: 120,
    objectFit: 'contain',
  },
  submit: {
    minWidth: 146,
    backgroundColor: theme.palette.primary.default,
    boxShadow: '0px 2px 4px rgba(33, 33, 33, 0.1)',
    borderRadius: 8,
    minHeight: 46,
    '& > span': {
      color: '#ffffff',
      fontSize: '1rem !important',
    },
  },
  subtitle: {
    fontSize: '1rem',
    color: '#242428',
  },
}));
const translations = utils.translations.getTranslations({
  keyPrefix: 'auth_page:forgot.reset_sent',
  defaults: [
    ['title', 'Check your email'],
    ['subtitle', 'We sent you a link to reset your password'],
    ['back_to_sign_in', `Back to sign in`],
  ],
});

const ResetSent = () => {
  const classes = useStyles();
  const [title, subtitle, backToSignIn] = useTranslations(translations);
  const returnToLogin = () => {
    utils.eventsLogger.unifiedLoginDio.track('back_to_sign_in', 'click');
    history.go('login');
  };
  return (
    <>
      <Icon svg={SentIcon} className={classes.icon} />
      <Col align="center" justify="center" spacing={1}>
        <Title>{title}</Title>
        <Subtitle className={classes.subtitle}>{subtitle}</Subtitle>
      </Col>
      <RaisedButton className={classes.submit} onClick={returnToLogin}>
        {backToSignIn}
      </RaisedButton>
    </>
  );
};
export default ResetSent;
