import React from 'react';
import Spinner from '@guestyci/foundation/Spinner';
import TextField from '@guestyci/foundation/TextField';
import Col from '@guestyci/foundation/Layout/Col';
import createStyles from '@guestyci/foundation/createStyles';

export const useStyles = createStyles(
  theme => ({
    spinner: {
      width: 30,
      height: 30,
    },
    text: {
      color: theme.palette.label,
    },
  }),
  { name: 'Loader' }
);

const Loader = ({ text }) => {
  const classes = useStyles();

  return (
    <Col spacing={4}>
      <div>
        <Spinner size={30} className={classes.spinner} />
      </div>
      <TextField style={{ fontSize: '1.2rem' }} className={classes.text}>
        {text}
      </TextField>
    </Col>
  );
};

export default Loader;
