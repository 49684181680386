const productionOrigins = ['https://app.guesty.com', 'https://www.guesty.com'];

export const postMessageToParent = message => {
  if (window.self === window.top) {
    console.log('Not in an iframe, message not sent');
    return;
  }

  const parentOrigin = document.referrer
    ? new URL(document.referrer).origin
    : null;

  if (productionOrigins.includes(parentOrigin)) {
    window.parent.postMessage(message, parentOrigin);
    console.log(`Message sent to production origin: ${parentOrigin}`);
  } else {
    console.error(`Attempted to send message to non-allowed origin in production: ${parentOrigin}`);
  }
};

export const isAllowedOrigin = () => {
  const origin = window.location.origin;
  return productionOrigins.includes(origin);
};

export const getWidgetConfig = oidc => {
  const { issuer, clientId, scopes, redirectUri, googleIDPId } = oidc;
  return {
    baseUrl: issuer.split('/oauth2')[0],
    clientId,
    redirectUri,
    authParams: {
      issuer,
      scopes,
      prompt: 'consent',
    },
    features: {
      registration: false,
      rememberMe: false,
      showPasswordToggleOnSignInPage: false,
      idpDiscovery: false,
    },
    idps: [{ type: 'GOOGLE', id: googleIDPId }],
    prompt: 'consent',
    idpDisplay: 'PRIMARY',
  };
};
