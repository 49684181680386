export default {
  production: {
    // TODO: Update production info
    clientId: '0oalw4lgrsUBQk2yG5d7',
    issuer: 'https://login.guesty.com/oauth2/auslw4kic6gLU6ZL85d7',
    googleIDPId: '0oalw4p0sz8mMX3F65d7',
  },
  staging5: {
    clientId: '0oalmpcpwauuv3LhL5d7',
    issuer: 'https://login-staging5.guesty.com/oauth2/auslmpnx3d7NMUhBG5d7',
    googleIDPId: '0oalkgyhby0VWj9KK5d7',
  },
};
