import React from 'react';
import createHistory from '@guestyci/history/createHistory';
import LocalizationProvider from '@guestyci/localize/LocalizationProvider';
import { FeatureToggleProvider } from '@guestyci/feature-toggle-fe';
import { Route, Router, Switch } from 'react-router-dom';
import '@guestyci/foundation/style/styles.css';
import ToastProvider from '@guestyci/foundation/Toast';

import { utils } from 'utils';
import { getEnv } from 'utils/env';

import App from './app/App';
import GoogleConnect from './google-connect';

utils.eventsLogger.initLoggers();

const { env } = getEnv();

const history = createHistory({ baseUrl: '/auth', isFragment: false });

const AppProvider = () => {
  return (
    <Router history={history}>
      <LocalizationProvider>
        <FeatureToggleProvider env={env} accountId="anonymus">
          <ToastProvider>
            <Switch>
              <Route exact path="/google-connect" component={GoogleConnect} />
              <Route path="/" component={App} />
            </Switch>
          </ToastProvider>
        </FeatureToggleProvider>
      </LocalizationProvider>
    </Router>
  );
};

export { AppProvider };
